
import script from '/home/circleci/app/packages/layout-user-session-component/src/entries/UserSession/index';
import { handleCSRError } from '@fiverr-private/layout_lib/CSRTemplate';

try {
    const props = window.initialData['UserSession'];
    script(props);
}
catch(error) {
    handleCSRError(error, "UserSession", "UserSession")
}
